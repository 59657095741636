var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e1e9a9275d4c89f64e2e63fe532f01b51b8fd445"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  CaptureConsole as CaptureConsoleIntegration,
  HttpClient as HttpClientIntegration,
} from '@sentry/integrations'
const isAbortError = (event) => {
  return event?.message?.match(/AbortError/i)
}

const isUserNotAuthenticatedError = (event) => {
  return event?.message?.match('User is not authenticated')
}

const isProd = process.env.NEXT_PUBLIC_ENV === 'production'
const isPreview = process.env.NEXT_PUBLIC_ENV === 'preview'
const isEnabled = isPreview || isProd

if (isEnabled) {
  Sentry.init({
    attachStacktrace: true,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    enabled: isEnabled,
    environment: process.env.NEXT_PUBLIC_ENV,
    sendDefaultPii: true, // required for capturing headers and cookies
    tracesSampleRate: 0.25,
    replaysSessionSampleRate:
      parseInt(process.env.NEXT_PUBLIC_REPLAYS_SESSION_SAMPLE_RATE) || 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new ExtraErrorDataIntegration({ depth: 3 }),
      new CaptureConsoleIntegration({ levels: ['error'] }),
      new HttpClientIntegration({
        failedRequestStatusCodes: [[500, 599]],
      }),
    ],
    beforeSend(event) {
      if (isAbortError(event) || isUserNotAuthenticatedError(event)) {
        return null
      }

      return event
    },
    ignoreErrors: ['ResizeObserver loop completed with undelivered notifications.'],
  })
}
